import '../Styling/locationcard.css'
import { Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useFirestore } from "../../hooks/useFirestore"
import { useAuthContext } from '../../hooks/useAuthContext'
import { arrayUnion } from 'firebase/firestore'
import ProductModal from './ProductModal'
import { useState } from 'react'

export default function LocationCard({ location }){
    
    const { locationName, street, city, state, phone, id, Menus } = location
    const { user } = useAuthContext()
    const [menuType, setMenuType] = useState('')
    const [showProductModal, setShowProductModal] = useState(false)

    // Firebase functions
    const collectionRef = `users/${user.uid}/locations/`
    const menuRef = `users/${user.uid}/locations/${id}/Menus` 
    const { updateDocument } = useFirestore(collectionRef)
    const { addDocument } = useFirestore(menuRef)
    
    // Created new menu name on location card
    const handleClick = (e) => {
      e.preventDefault()
      const doc = {
        menuType: menuType,
        locationID: id,
        Categories: [] }
    
      const menu = { Menus: 
        arrayUnion ({ 
          menuType: menuType,
          id: menuType })} 
        
    // Adds reference to Menus array and creates new Menu in Menus collection
       updateDocument(menu, id)
       addDocument(doc, menuType)
       setShowProductModal(false)
       setMenuType('')
    }

    // closes modal 
    const handleClose = (e) => {
        e.preventDefault()
        setShowProductModal(false)
    }

    return(
      <div className="bg-light location-card shadow">
        <div className="container">
          <h1 className='location-text'>{locationName}</h1>
          <p className='location-text'>{street}
          <span> {city}, {state} </span></p>
          <p className='location-text'>{phone}</p>
          <hr className='hr-bottom'></hr>
             
        <div className='container-fluid'>
          <div className='row'>
            {!Menus ? <div><h5>Please create a menu!</h5></div> : 
            <div><h5 className='location-menu-title'>Available Menus</h5></div>}
            {Menus && Menus.map(menu => {
              return <div className='col mx-auto my-auto'>
                <Link className='menu-items btn btn-outline-danger'
                  to={`/Products/${id}`}>
                  {menu.menuType}
                </Link></div>})}
          </div>
        </div>
        <hr className='hr-bottom'></hr>
                </div>
        <div>

        </div>
    
        <Button 
          className="btn btn-danger align-self-center"
          onClick={(e) => {setShowProductModal(true)}}>
            Add Menu
        </Button>

        <ProductModal 
          handleClose={handleClose}
          id = { id }
          showProductModal={showProductModal}> 
            <Form className='text-center' onSubmit={handleClick}>
              <Form.Group>
                <Form.Label className='h4 text-muted'> Menu Name </Form.Label>
                <Form.Control 
                  value={menuType}
                  onChange={(e) => setMenuType(e.target.value)}
                  placeholder='Food, Desserts, Drinks Menu ....'>
                </Form.Control>
              </Form.Group>
              <hr className='mt-4'></hr>
                <Button 
                  onClick={handleClick}
                  className='btn btn-danger text-center'> Save
                </Button>
             </Form>
        </ProductModal>
      </div>
    )
}