import { useAuthContext } from "../../hooks/useAuthContext"
import { useCollection } from "../../hooks/useCollection"
import { useState } from "react"
import { useLogOut } from "../../hooks/useLogOut"
import ProductModal from "../Components/ProductModal"
import LocationForm from "../Components/LocationForm"
import LocationCard from "../Components/LocationCard"
import SideBarTest from "../Components/SideBarTest"

export default function Location(){

  const { user } = useAuthContext()
  const [showProductModal, setShowProductModal] = useState(false)
  const collectionRef = `users/${user.uid}/locations`
  const { documents: Locations } = useCollection(collectionRef)


  const handleClose = () => setShowProductModal(false)

  return(
        <div>
          <SideBarTest user = {user} />
          <main class="location col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 class="display-6 text-muted">Manage menus </h1>
              <div class="btn-toolbar mb-2 mb-md-0">
               <div class="btn-group me-2">
                  <button type='button'
                          onClick={() => setShowProductModal(true)}
                          class="btn btn-md btn-danger">
                            + Location
                  </button>
                </div>
                <ProductModal handleClose={ handleClose }
                              showProductModal={ showProductModal }>
                              <LocationForm handleClose={ handleClose } />
                </ProductModal>
              </div>
           </div>
           <div className="container">
                <div className="row">
                    {Locations && Locations.map(location => {
                      return <div className="col-lg-6"><LocationCard location={location} /></div>
                    })}
                </div>
              </div>
          </main>
        </div>
    )
}