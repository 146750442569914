import { Fade } from 'react-awesome-reveal'
import { useCollection } from '../../../hooks/useCollection'
import '../../Styling/pricing.css'
import PricingCard from './PricingCard'

export default function Pricing(){
 
     const q = 'active' == 'true'
     const { documents: listOfProducts } = useCollection('products', q)

    return(
        <div id='pricing' className='container-fluid pricing'>
            <div className='container-blur'>
            <Fade cascade className='container text-center pb-5'>   
            <div class="pricing-header text-center">
                <h5 class="display-3 pt-5 mb-2">Pricing</h5>
                <p class="display-6">
                    Our free plan allows you to create your digital menu without a credit card. 
                    If you'd like all the other awesome features you can upgrade your plan at anytime.
                </p>
            </div>
            <div class="container text-center">
                <div class="card-deck">
                 {listOfProducts && listOfProducts.map(product => { 
                     return <PricingCard product={product} /> 
                  })}
                </div>
              </div>
              </Fade>
            </div>
        </div>
    )
}