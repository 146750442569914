export default function BasicPlanFeatures()  {
    return (
        <>
        <hr className="shadow"></hr>
        <ul class="list-unstyled lead mt-3 mb-4">
          <li className="lead text-muted">1 Menu included</li>
          <li className="lead text-muted">Up to 15 Menu items</li>
          <li className="lead text-muted">500 MB of storage</li>
          <li className="lead text-muted">-</li>
          <li className="lead text-muted">-</li>
        </ul>
        </>
    )
}