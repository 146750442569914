import Dashboard from "./Dashboard";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import SideBarTest from "../Components/SideBarTest";


export default function Products(){

  const { id } = useParams()
  const{ user } = useAuthContext()

  return(
    <>
      <SideBarTest user={ user }/>
      <Dashboard id = { id } mainPageTitle = { 'Menus' }/>
    </>
    )
}