export default function PremiumPlanFeatures() {
    return(
    <>  
    <hr className="shadow"></hr>                  
      <ul class="lead list-unstyled mt-3 mb-4">
        <li className="text-muted lead">Unlimited Menus</li>
        <li className="text-muted lead">15 GB of storage</li>
        <li className="text-muted lead">First 100 QR code stickers</li>
        <li className="text-muted lead">Phone and email support</li>
        <li className="text-muted lead">Help center access</li>
      </ul>
    </>
)
}