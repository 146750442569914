import { useState, useEffect } from "react"
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage"

export const useStorage = (file, folder, uid, savedURL) => {

  const [progress, setProgress] = useState(0)
  const [error, setError] = useState(null)
  const [url, setUrl] = useState(null)
  const storage = getStorage()

  // runs every time the file value changes
  useEffect(() => {
    if (file) {
      // storage ref
      const storageRef = ref(storage, uid + folder + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file)  
      uploadTask.on(
        "state_changed",
        (snap) => {
      // track the upload progress
          let percentage =
              Math.round(
              (snap.bytesTransferred / snap.totalBytes) * 100
          );
          setProgress(percentage)
        },
        (err) => {
          setError(err)
        },
        async () => {
      // get the public download img url
          getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadUrl) => {
            // save the url to local state
            setUrl(downloadUrl);
          })
        }
      )
      } else {
        if(savedURL){
          setUrl(savedURL)
        }
      }
  }, [file, storage, uid, savedURL, folder]) 

  return { progress, url, error }
}