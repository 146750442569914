import { useState } from "react";
import { Form, Image, ProgressBar, Button } from "react-bootstrap";
import { useStorage } from "../../hooks/useStorage";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useFirestore } from "../../hooks/useFirestore";

export default function AddPromotionForm( { handleClose, promotionsCollectionRef } ){

    const { user } = useAuthContext()
    const [ promotionTitle, setPromotionTitle ] = useState('')
    const [ promotionDescription, setPromotionDescritpion ] = useState('')
    const [ promotionStart, setPromotionStart ] = useState('')
    const [ pormotionExpires, setPromotionExpires ] = useState('')
    const [ active, setActive ] = useState(false)
  
    const { addDocument } = useFirestore(promotionsCollectionRef)

    // Image upload const
    const [file, setFile] = useState(null)
    const [error, setError] = useState(null)
    const [folder, setFolder] = useState('')
    const { progress, url } = useStorage(file, folder, user.uid)
    const types = ['image/png', 'image/jpeg', 'image/jpg']

    const handleFileChange = (e) => {
        e.preventDefault()
        let selectedFile = e.target.files[0]
        if (selectedFile){
            if(types.includes(selectedFile.type)){
                setError(null)
                setFile(selectedFile)
                setFolder('/Promotions/')
            }else{
                setFile(null)
                setError('Please select an imagee file (png or jpg)')
            }
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        const newPromotion = {
            promotionTitle: promotionTitle,
            promotionDescription: promotionDescription,
            promotionStart: promotionStart,
            promotionExpires: pormotionExpires,
            promotionImage: url,
            active: active
        }
        addDocument(newPromotion)
        handleClose(false)
    }

    return(
        <>
          {/* Title, description, photo, valid through, expiration date, */}
          <Form > 
              <Form.Group>
                <Form.Label>
                  Title
                </Form.Label>
                <Form.Control
                 value={promotionTitle} 
                 placeholder='Happy hour Tuesdays ...'
                 onChange={(e) => setPromotionTitle(e.target.value)}>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Description
                </Form.Label>
                <Form.Control
                 value={promotionDescription} 
                 placeholder='Join us every Tuesday from 3 to 5 pm. Drinks are 50% off and wings 50 cents!'
                 onChange={(e) => setPromotionDescritpion(e.target.value)}>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Promotion Start Date
                </Form.Label>
                <Form.Control
                 data-date-format="mm/dd/yyyy"
                 value={promotionStart} 
                 type='date'
                 placeholder='Today'
                 onChange={(e) => setPromotionStart(e.target.value)}>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Expiration Date
                </Form.Label>
                <Form.Control
                 type="date"
                 value = { pormotionExpires } 
                 placeholder='Expiration date'
                 onChange={(e) => setPromotionExpires(e.target.value)}>
                </Form.Control>
              </Form.Group>

              <Form.Group className="mt-3 mb-3 align-items-middle">
                <Form.Check 
                 inline
                 label='Enable'
                 value={active} 
                 onChange={() => setActive(!active)}>
                </Form.Check>
              </Form.Group>
              
              <Form.Group className='form-group'>
                <Form.Label> Select Image
                <Form.Control 
                  className='image-upload'
                  type='file'
                  onChange = { handleFileChange }>
                </Form.Control>
                </Form.Label>
                  <div className='flex'>
                  <p className='p-img-prev'>Image Preview</p>
                    {error && <p>{ error }</p>}
                    {url && 
                    <Image 
                      className='image-preview'
                      src={url}
                      alt='Menu Item'>
                    </Image>}
                     {file && 
                    <ProgressBar 
                      striped 
                      now={progress} 
                      label={`${progress}%`}
                    />}
                 </div>
              </Form.Group>
           <hr></hr>
           <Form.Group className='form-group buttons fluid'>
          <Button 
              className="mr-3"
              onClick={() => handleClose(false)}
              variant='secondary'>
              Cancel
          </Button>
        
          <Button 
              onClick = { handleSubmit }
              type='submit' 
              variant='danger'>
              Save
          </Button>
        </Form.Group>
          </Form>
        </>
    )
}