export default function StandardPlanFeatures() {
    return (
      <>
      <hr className="shadow"></hr>
        <ul class="lead list-unstyled mt-3 mb-4">
          <li className="lead text-muted">2 Menus included</li>
          <li className="lead text-muted">Cocktails Menu</li>
          <li className="lead text-muted">2 GB of storage</li>
          <li className="lead text-muted">10 QR code stickers </li>
          <li className="lead text-muted">-</li>
        </ul>
      </>
    )
}