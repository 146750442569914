import { Modal } from 'react-bootstrap'

export default function ProductModal({ children, handleClose, showProductModal }){
  return(
    <Modal 
      className='modal' 
      show={showProductModal} 
      centered>
        <Modal.Header 
          closeButton 
          onClick={handleClose}>
        </Modal.Header>
        <Modal.Body>
          { children }
        </Modal.Body>
    </Modal>
  )
}