
export default function MenuItem(props){

    const { name, calories, description, price, image } = props.menuItem
    return (
    <div className="container">
      <div className="row shadow-lg rounded pt-2 pb-2 mb-3 bg-white">
          <div className="col-sm my-auto">
             {!image ? <></> : <img className="menu-item-img" src={image} alt='food'/>}
          </div>
          <div className="col-sm my-auto">
              <h5 className="mt-3 display-5">{name}</h5>
              <p className="lead">{description} <span>{calories && `${calories} cals`}</span></p>
          </div>
          <div className="col-sm my-auto">
              <p>{`$${price}`}</p>
          </div>
      </div>
    </div>
    )
}