
import { useState } from "react"
import { useLogIn } from "../../hooks/useLogIn"
import { Link } from 'react-router-dom'
import { useResetPassword } from "../../hooks/useResetPassword"
import '../Styling/sigin.css'

export default function SignIn(){

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { error, logIn } = useLogIn()
  const { resetPassword } = useResetPassword()
  
  const handleSubmit = (e) => {
    e.preventDefault()
    logIn(email, password)
  }
  const handlePasswordReset = (e) => {
    e.preventDefault()
   
    resetPassword(email)

  }
  return(
    <main className="center-screen main-signin">
      <div className="form-signin">
        <form onSubmit={handleSubmit}>
          <Link to={'/'}>
              <img className="mb-4" src={require('../../Assets/logo.png')} alt="" width="90" height="90"/>
          </Link>
          <h1 className="h3 mb-3 fw-normal">Please sign in</h1>  
          <div className="form-floating">
          <input  
            required 
            onChange={(e) => setEmail(e.target.value)} 
            value={email}
            type="email" 
            class="form-control" 
            id="floatingInput" 
            placeholder="name@example.com"/>  
          <label HTMLfor="floatingInput">
              Email address
          </label>
          </div>  
          <div className="form-floating">
          <input 
            type="password"
            required 
            className="form-control" 
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            id="floatingPassword" 
            placeholder="Password"/>
          <label HTMLfor="floatingPassword">
              Password
          </label>
          </div>
          
          <button 
            className="w-100 btn btn-lg btn-danger" 
            type="submit">Sign in
          </button>
          <br></br>
          <p>Not registered yet, please <Link className='link-danger' to='/'>Sign Up</Link></p>
          <p className="mb-3 text-muted">{error && <p>{error}</p>}</p>
          <a href='#' className="link-danger" onClick={handlePasswordReset}>Reset Password</a> 
        </form>
      </div>
      
    </main>    
  )
}