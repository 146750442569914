import { Timestamp } from "firebase/firestore";
import { Button,  Card, Col, Row } from "react-bootstrap";
import { useFirestore } from "../../hooks/useFirestore";
import { useAuthContext } from "../../hooks/useAuthContext";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Edit } from "react-feather";


export default function PromotionCard( { promotion, locationID } ){
    
    const { user } = useAuthContext()
    const deleteDocRef = `users/${user.uid}/locations/${locationID}/promotions/`
    const { deleteDocument } = useFirestore(deleteDocRef)

    const deleteDoc = (e) => {
        e.preventDefault()
        deleteDocument(e.target.value, deleteDocRef)
    }
    return(
    <>
    <Row xs={1} md={2} className="g-4">
     <Col>
      <Card id={promotion.id} className="mb-3 shadow text-center">
        <CardHeader className="lead">{promotion.promotionTitle}
        </CardHeader>
        <Card.Img variant="top" src={promotion.promotionImage} alt='' />
        <Card.Body>
            <Card.Text className="lead">{promotion.promotionDescription}</Card.Text>
            <Card.Text>{`Active: ${promotion.active}`}</Card.Text>
            <Card.Text className="text-muted mt-3">
              {`Starts: ${promotion.promotionStart}`}
            </Card.Text>
            <Card.Text className="text-muted mt-3">
              {`Expires: ${promotion.promotionExpires}`}
            </Card.Text>

            <Button variant="danger mr-2">
              <Edit />
            </Button>
            
            <Button variant="danger mr-2"
              value={promotion.id}
              onClick={deleteDoc}>
                Delete
            </Button>

        </Card.Body>
        <Card.Footer>
          <Card.Text className="text-muted">
            {`Created: ${Timestamp.fromDate(new Date(promotion.createdAt.seconds*1000 )).toDate().toDateString()}`}
          </Card.Text>
        </Card.Footer>
      </Card>
     </Col>
     </Row>
    </>
    )
}