import ProductModal from '../Components/ProductModal'
import AddItemForm from '../Components/AddItemForm'
import CategoryForm from '../Components/CategoryForm'
import CategoryModal from '../Components/CategoryModal'
import MenuCards from '../Components/MenuCards'
import { useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useLogOut } from '../../hooks/useLogOut'
import { useCollection } from '../../hooks/useCollection'
import { useGetDocument } from '../../hooks/useGetDocument'
import { Spinner } from 'react-bootstrap'

export default function Dashboard({ id }){

  const { user } = useAuthContext()
  const { logOut } = useLogOut()
  // Firebase functions
  const collectionRef = `users/${user.uid}/locations/`
  const menuCallectionRef = `users/${user.uid}/locations/${id}/Menus/`
  const { documents: ListofMenus } = useCollection(menuCallectionRef)
  const [showProductModal, setShowProductModal] = useState(false)
  const [showCategoryModal, setCategoryModal] = useState(false)
  const { document: locationData } = useGetDocument(collectionRef, id)
  
  const handleClose = () => setShowProductModal(false)
  const closeCategoryModal = () => setCategoryModal(false)


    return(
      <main class="location col-md-9 ms-sm-auto col-lg-10 px-md-4 bg-light">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          {!locationData ? <Spinner animation='grow' />  : <h1 class="h2 text-secondary">{locationData.locationName}'s Menus</h1>}
          <div class="btn-toolbar mb-2 mb-md-0">
            <div class="btn-group me-2 ">
              <button 
                type="button" 
                class="btn btn-md btn-danger"
                onClick = { logOut } >
                  Sign Out
              </button>
            </div>
          </div>
        </div>
        <div >
          {ListofMenus && 
           ListofMenus.map(menu => {
            return (
              <>
             <MenuCards 
              category = { menu.id }
              categories = { menu } 
              id = { id }>
             </MenuCards></>)})}
        </div>
        <hr></hr>
        
        <ProductModal 
          handleClose = { handleClose }
          id = { id }
          showProductModal = { showProductModal }> 
            <AddItemForm handleClose={handleClose}/>
        </ProductModal>

        <CategoryModal 
          handleClose = { handleClose }
          showCategoryModal = { showCategoryModal }
          id = { id } >
            <CategoryForm 
              closeCategoryModal={closeCategoryModal}
              id = { id }/>
        </CategoryModal>

      </main>
    )
}