import '../Styling/home.css'
import Features from './HomeComponents/Features'
import HomeMain from './HomeComponents/HomeMain'
import Pricing from './HomeComponents/Pricing'
import NavBar from '../Components/NavBar'
import About from './HomeComponents/About'
import Footer from './HomeComponents/Footer'
import Contact from './HomeComponents/Contact'
//import HomeHeader from '../Components/HomeHeader'

 
export default function Home(){
    return(
     <>
      <NavBar />
      <HomeMain />
      <Features />
      <About />
      <Pricing />
      <Contact />
      <Footer />
     </>
    )
}