export default function About(){
    return(
        <div id='about' className="container text-center mt-5">
           <h1 className="display-3 mb-5 text-muted">ABOUT US</h1>
           <div className="row">
           <div className='mt-2 mb-5 px-3'>
               <p className='lead text-muted'><b>ORDENA NOW</b> is a company founded by a family of mexican restaurant owners. 
                   As a small restaurant owners, we realize the challenges you have 
                   as an entrepeneur competing with big restaurant chains. 
                   Our commitment,
                   is to help you implement technologies used by big chains at affordable cost to you.
                   Take you restaurant to the next level digitizing your processes without paying the 
                   astronomical fees charge by other companies.
                </p>  
           </div>
           <div className='mb-5 w-100'>
              <img className='shadow-lg rounded-3' width='100%' src={require('../../../Assets/street-food.jpg')} alt='family' />
           </div>
           </div>
        </div>
    )
}