import { Modal } from 'react-bootstrap'

export default function ModalComponent({ children, title, handleClose, showModal }){
  return(
      <Modal className='modal' show={showModal} centered>
        <Modal.Header className='lead' closeButton onClick={handleClose}>
          {title}
        </Modal.Header>
          <Modal.Body>
           { children }
          </Modal.Body>
      </Modal>
  )
}