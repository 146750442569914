import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import { Routes, Route, BrowserRouter} from 'react-router-dom'
import SignUp from './components/Pages/SignUp';
import Home from './components/Pages/Home';
import SignIn from './components/Pages/SingIn';
import Location from './components/Pages/Location';
import { useAuthContext } from './hooks/useAuthContext'
import Products from './components/Pages/Products';
import LocationMenu from './components/Pages/CustomerMenuPage/LocationMenu';
import QRCodeMenu from './components/Pages/CustomerMenuPage/QRCodeMenu';
import NewUser from './components/Pages/NewUser';
import Settings from './components/Pages/Settings';
import Promotions from './components/Pages/Promotions';


function App() {

  const {user, authIsReady } = useAuthContext()

  return (
    <div>
    {authIsReady && (
      <BrowserRouter>
        <Routes>
          <Route exact path='/' 
                 element={<Home/>}/>
          <Route path='/:uid/locations/:locationID/Menus/:type/*'
                 element={<LocationMenu />}/>
          <Route path='/qrcode' 
                 element={<QRCodeMenu />}/>
          <Route path='/signup/:productid'
                 element = { !user ? <SignUp /> : <Location/> }/>
          <Route path='/signin'
                 element = { !user ? <SignIn /> : <Location/> }/>
          <Route path='/Location'
                 element = { user ? <Location /> : <SignIn/> } />
          <Route path='/newuser/:productid' 
                 element = { user ? <NewUser /> : <SignIn/> } />
          <Route path='/Products/:id'
                 element = { user ? <Products /> : <SignIn/> } />
          <Route path='/settings'
                 element = { user ? <Settings /> : <SignIn/> } />
          <Route path='/promotions'
                 element = { user ? <Promotions /> : <SignIn/> } />
        </Routes>
      </BrowserRouter>
    )}
    </div>
  );
}

export default App;
