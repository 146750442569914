import { Home, File, LogOut } from 'react-feather'
import { Collapse, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useLogOut } from '../../hooks/useLogOut'
import { useGetDocument } from '../../hooks/useGetDocument'
import '../Styling/style.css'
import { useState } from 'react'


export default function SideBarTest( { user } ){
  const { logOut } = useLogOut()
  const [ openMenu, setOpenMenu ] = useState(true)
  const { document: userProfile } = useGetDocument('users', user.uid)

  return(
    <div>
       <nav class="navbar navbar-dark bg-dark .d-none .d-sm-block .d-md-none">
         <button class="navbar-toggler bg-danger text-white" 
           type="button" 
           data-toggle="collapse" 
           data-target="#sidebarMenu" 
           aria-controls="sidebarMenu" 
           aria-expanded="false"
           onClick={() => setOpenMenu(!openMenu)}>
           <span class="navbar-toggler-icon text-white py-auto"></span>
         </button>
          <a href="/" class="d-flex align-items-center link-dark text-decoration-none">
             <span class="fs-4 nav-link">ORDENA NOW</span>
           </a>
           <Link className="text-white span-text-link btn btn-danger" to={'/'} onClick={logOut}>
                   Log Out
           </Link>
       </nav>
       <Collapse className='bg-dark sidebar' in={openMenu} dimension='width'>
         <div id="sidebarMenu" class="sidebar shadow-lg col-md-3 col-lg-2 d-flex flex-column flex-shrink-0 p-3 bg-dark">
           <div className='d-none d-lg-block'>
             <h3 className='text-white'>Menu</h3>
             <hr></hr>
           </div>
           
           <ul class="nav nav-pills flex-column mb-auto">
               <li class="nav-item">
                  <Link className='nav-link' to='/Location'>
                  <span className='span-text-link'><Home/></span>
                      Menus
                  </Link>
               </li>
               <li class="nav-item">
                  <Link className='nav-link' to='/promotions'>
                      <span className='span-text-link'><File/></span>
                      Promotions
                  </Link>
                </li>
             <li>
               <a href="#" class="nav-link link-dark">
                 Orders
               </a>
             </li>
             <li>
               <a href="#" class="nav-link link-dark">
            
                 Products
               </a>
             </li>
             <li>
               <a href="#" class="nav-link link-dark">
                
                 Customers
               </a>
             </li>
           </ul>
           <hr></hr>
           <div class="dropdown">
             <a href="/settings" class="d-flex align-items-center nav-link link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
              {!userProfile ? <img src={require('../../Assets/user-profile.png')} alt='user' width="32" height="32" class="rounded-circle me-2"/> : 
                              <img src={userProfile.photoURL} alt="user" width="32" height="32" class="rounded-circle me-2"/>}
              {!userProfile ? <strong><Spinner animation='grow' /></strong> : <strong>{userProfile.displayName}</strong>}
             </a>
             <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
               <li><a class="dropdown-item" href="/settings">Settings</a></li>
               <li><hr class="dropdown-divider"/></li>
               <li class="nav-item">
                     <Link className="text-dark span-text-link" to={'/'} onClick={logOut}>
                       <span className='span-text-link text-dark'><LogOut/></span>
                       Log Out
                     </Link>
                   </li>
             </ul>
           </div>
          </div>
        </Collapse>
    </div>
 
    )
}