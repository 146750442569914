import { QRCode } from 'react-qrcode-logo'


export default function QRCodeMenu(props){
    return (
        <>
          <div>
              <QRCode value='http://ordenanow-a6fb6.web.app//Location/xQaQHqOh9wawZGthMJ7kJXElCQE3/locations/fhCCHtPBdqvgeNXU2JFH/Menus/Desserts/' />
          </div>
        </>
    )
}