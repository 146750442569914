import { useState } from "react"
import { useParams } from "react-router-dom"
import { useCollection } from "../../../hooks/useCollection"
import { useGetDocument } from "../../../hooks/useGetDocument"
import { Spinner } from "react-bootstrap"
import { useAuthContext } from "../../../hooks/useAuthContext"
import { QRCode } from 'react-qrcode-logo'
import '../CustomerMenuPage/customer-menu-style.css'
import Offers from "./Components/Offers"
import MenuItem from "./Components/MenuItem"

export default function LocationMenu(){

    let params = useParams()
    const { user } = useAuthContext()
    const [ categorySelection, setCategorySelection ] = useState('*')
    const collectionRef = `users/${params.uid}/locations/${params.locationID}/Menus/`
    const menuItemsCollectionRef =  `users/${params.uid}/locations/${params.locationID}/Menus/${params.type}/`
    const locationRef = `users/${params.uid}/locations/`
    const qrURL = `/${params.uid}/locations/${params.locationID}/Menus/${params.type}`
    const { documents: menuItems } = useCollection(`${menuItemsCollectionRef}${categorySelection}`)
    const { document: Categories } = useGetDocument(collectionRef, params.type)
    const { document: locationData } = useGetDocument(locationRef, params.locationID)
    const { documents: offers } = useCollection(`users/${params.uid}/locations/${params.locationID}/promotions`)

    return(
        <div className="container-fluid shadow-lg text-center lead pt-3" >
          {user ? <div className="mb-4 pt-4">
              <QRCode value = { `https://ordenanow-a6fb6.web.app/${qrURL}` }
              logoImage={require('../../../Assets/logo.png')}
              eyeRadius={5}
              size={200}/> </div>: <></>}
          {!locationData ? 
            <Spinner animation="grow" variant="danger" role='status'/> : 
            <h1 className="display-3" >{locationData.locationName}</h1>
          }

          {!locationData ? 
            <Spinner animation="grow" variant="danger" role='status'/> : 
            <><p>{locationData.street}<span> {locationData.city}, {locationData.state}</span></p> </>
          }

          {!locationData ? 
            <Spinner animation="grow" variant="danger" role='status'/> : 
            <p>{locationData.phone}</p>
          }

         {offers ? 
          <div className="offers-carousel mb-5 shadow">
           <Offers offers = { offers }/> 
          </div> : <></>}

          <hr></hr>
          <h1 className="display-5">Menu</h1>          
          <div className="pt-4">
              {!Categories ? 
                <Spinner animation="grow" variant="danger" role="status">
                   <span className="visually-hidden">Loading...</span>
                </Spinner> :
                 Categories.Categories.map(item => { return <a
                       className = "mx-2 mb-2 display-6 text-danger"
                       onClick={() => setCategorySelection(item.categoryName)}>
                           {item.categoryName} 
                        </a>
                    })
                }
          </div>
          <hr></hr>

          <div>{!menuItems ? <div><Spinner animation="grow" variant="danger" role='status'></Spinner></div> : 
              menuItems.map(item => {
                  return <MenuItem menuItem = {item} />
              })}
          </div>
          <div className="container pb-2 pt-5">
              <p className="lead">Digital Menu Powered by</p>
              <a href="/">Ordena Now</a>
          </div>
        </div> 
    )
}