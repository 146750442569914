import CategoryForm from './CategoryForm'
import { useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useGetDocument } from '../../hooks/useGetDocument'
import { useCollection } from '../../hooks/useCollection'
import { useFirestore } from '../../hooks/useFirestore'
import { useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import AddItemForm from './AddItemForm'
import CategoryModal from './CategoryModal'
import Card from './Card'
import '../Styling/menu-card.css'
import { Trash2 } from 'react-feather'


export default function MenuCards({ category, id }){

  const { user } = useAuthContext()
  const navigate = useNavigate()
  
  const [ selection, setSelection ] = useState('*') 
  const menuCategoryRef = `/users/${user.uid}/locations/${id}/Menus/`

  const [ showCategoryModal, setCategoryModal ] = useState(false)
  const closeCategoryModal = () => {
    setCategoryModal(false)
  }

  const [ showProductModal, setShowProductModal ] = useState(false)
  const closeProductModal = () => setShowProductModal(false)

  const [ rerender, setRerender ] = useState(true)

  const menuItmesRef = `/users/${user.uid}/locations/${id}/Menus/${category}/${selection}`
  const deleteMenuRef = `/users/${user.uid}/locations/${id}/Menus/`
  const previewLink = `/${user.uid}/locations/${id}/Menus/${category}/${selection}`
  const { documents: menuItems } = useCollection(menuItmesRef)
  const { document: menuCategories } = useGetDocument(menuCategoryRef, category)
  const { deleteDocument } = useFirestore(deleteMenuRef)

  ///users/xmvzbmvuR4VaD1sj8I8614TAGHu2/locations/VRHcttbAKQWOsLvm05js/Menus/Main/Lunch
  

  // closes modal 
  const closeModal = (e) => {
    e.preventDefault()
    setRerender(!rerender)
    setShowProductModal(false)
  }

  function handlePreview(){
     navigate(previewLink.slice(0, previewLink.length - 1))
  }
  function deleteMenu(e) {
    e.preventDefault()
    console.log(e.target.value)
    let id = e.target.value
    deleteDocument('Food', deleteMenuRef)

  }

  return(
    <div className='container-fluid tag mb-4 shadow-lg'>
      <div className='row align-items-center'>
        <div className='col'>
          <h2>{ category } </h2>
        </div>
        <div className='col'>
        </div>
        <div className='col col-auto ml-auto'>
          <p></p>
          <p className='btn btn-outline-danger'
          onClick = { () => setCategoryModal(true) } > + Category
          </p>
          <p className='btn btn-outline-danger ml-3'
          onClick = {handlePreview}>Preview</p>
        </div>  
        <hr className='menu-tag-hr'></hr>
      </div>
      <div className='cat-banner-section row'>
        <div className='col'></div>
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          {console.log(menuCategories)}
        {!menuCategories ? <Spinner animation='grow' /> :
          menuCategories.Categories.map(
           element => { 
             return( 
             <div >
               <div className='btn-group'>
                  <button
                    value = { element.categoryName }
                    aria-current='true'
                    onClick = {() => setSelection(element.categoryName)} 
                    type="button" 
                    class="btn btn-outline-danger">
                      {element.categoryName} 
                  </button>
                  <button className='btn btn-outline-danger mr-2' onClick={deleteMenu} value={selection}><Trash2  />
                    </button>
               </div>
          </div>)})}
          <div className='col col-auto ml-auto'> 
            {!menuCategories ? <Spinner animation='grow' /> : <button 
              className='btn btn-danger '
              value={selection}
              onClick={(e) => setShowProductModal(true)}>+ Product
             </button> }
             
          </div>
        </div>
      </div>  
       
      <div className='container-fluid'>
          <div className="row align-items-center">
            { menuItems && menuItems.map(product => {
              if(product.category === selection){
                return(
                 <div class='col-lg-6 col-sm'>
                  < Card menuCategories = { menuCategories } menu = { category } locationID = { id } product={ product }/>
                </div>)}
              else{
                return( 
                  <div className='container'>
                    <h5>So Empty Here. Please Add Products!</h5>
                  </div> )}
            })}   
            </div>
        </div>
        <CategoryModal 
          handleClose = { closeModal }
          showCategoryModal = { showCategoryModal }>
          <CategoryForm
            closeCategoryModal = { closeCategoryModal }  
            category = { category }
            id = { id }>
          </CategoryForm>
        </CategoryModal>
        <CategoryModal 
          handleClose = { closeModal }
          showCategoryModal = { showProductModal }>
            <AddItemForm
              closeProductModal = { closeProductModal }
              menu = { category }
              menuCategories = { menuCategories }
              id = { id }>
            </AddItemForm>
        </CategoryModal>
    </div>
  )
}