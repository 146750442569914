// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBVLshzxpjlRBBvV4kLNWDs49Mq8HQdFbU",
  authDomain: "ordenanow-a6fb6.firebaseapp.com",
  databaseURL: "https://ordenanow-a6fb6-default-rtdb.firebaseio.com",
  projectId: "ordenanow-a6fb6",
  storageBucket: "ordenanow-a6fb6.appspot.com",
  messagingSenderId: "537941407745",
  appId: "1:537941407745:web:635de5dda4c985ec8ea800",
  measurementId: "G-P4ZSD3EK66"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app)

export { 
    auth,
    db,
    storage
}
