import { useCollection } from "../../hooks/useCollection"
import { useGetDocument } from "../../hooks/useGetDocument";
import { useAuthContext } from "../../hooks/useAuthContext"
import { getFunctions, httpsCallable } from "firebase/functions";
import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useFirestore } from "../../hooks/useFirestore";
import { Spinner, Form, Button, Image, ProgressBar } from "react-bootstrap";
import { useStorage } from "../../hooks/useStorage";
import SideBarTest from "../Components/SideBarTest";
import ModalComponent from "../Components/ModalComponent";


export default function Settings(){

    const { user } = useAuthContext()
    const subscritionRef = `users/${user.uid}/subscriptions/`
    const q = 'status == active'

    const {documents: subscriptions} = useCollection(subscritionRef)
    const [subscription, setSubscription] = useState()
    const [isPending, setIsPending] = useState(false)
    const { document: userSettings } = useGetDocument("users", user.uid)
    const { updateDocument } = useFirestore('users')
    const [ name, setName ] = useState('') 
    
    // Image upload const
    const [file, setFile] = useState(null)
    const [error, setError] = useState(null)
    const [folder, setFolder] = useState('')
    const types = ['image/png', 'image/jpeg', 'image/jpg']
    const { progress, url } = useStorage(file, folder, user.uid)
   
    const functions = getFunctions();
    const customerPortal = httpsCallable(functions, 'ext-ordena-now-stripe-createPortalLink');
    const [ showModal, setShowModal ] = useState(false)
    const closeModal = () => setShowModal(false)
    
    const manageSubscription = () => {
      setIsPending(true)
      customerPortal({
        returnUrl: window.location.origin,
        locale: "auto", // Optional, defaults to "auto"
        //configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", 
      }).then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
        window.location.assign(data.url);
        setIsPending(false)
      })
    }
    
    useEffect(() => {
      let results = []
      subscriptions.forEach(subscription => {
        results.push(subscription)
      })
      setSubscription(results[0])
    },[subscriptions])

    const handleFileChange = (e) => {
      e.preventDefault()
      let selectedFile = e.target.files[0]
      if (selectedFile){
          if(types.includes(selectedFile.type)){
              setError(null)
              setFile(selectedFile)
              setFolder(`users/${user.uid}`)
          } else{
              setFile(null)
              setError('Please select an image file (png or jpg)')
          }
      }
    }
    const handleSubmit = (e) => {
      e.preventDefault()

      if(url){
        const updateUserInfo = {
          displayName: name,
          photoURL: url,
       }
       updateDocument(updateUserInfo, user.uid)
      }
      
      const updateUserInfo = {
        displayName: name
      }

      updateDocument(updateUserInfo, user.uid)
      
      setShowModal(false)
    }

    return(
    <>
     <SideBarTest user={ user }/>
     <main class="location col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="text-muted display-6">Manage Account</h1>
      </div>
      <div>
        {userSettings ? <div className="text-left ml-5">
          <h3 className="text-muted">Personal Information</h3>
            <img height='100px' width='100px' alt="user" className='rounded-circle ml-3 mb-3 mt-3' src= { userSettings.photoURL } />
          <p className="lead"><b>Display Name: </b>{userSettings.displayName}</p>
          <p className="lead"><b>Email: </b>{userSettings.email}</p>
          <a href={userSettings.stripeLink}>Stripe</a>
          <p></p>
          <button className="btn btn-danger mb-3"
           onClick={(e) => setShowModal(true)}>Edit Personal Information</button>
          <hr></hr>
        </div> : <Spinner animation = "grow"/>}
      </div>
      <div>

      {subscription ? <div className="text-left ml-5">
         <h3 className="text-muted">Account overview</h3>
         <p className="lead"><b>Current Plan: </b> {` ${subscription.role.toUpperCase()} PLAN`}</p>
         <p className="lead"><b>Status: </b> {` ${subscription.status.toUpperCase()}`}</p>
         {/* created current_period_start */}
         <p className="lead"> <b>Subscription Started: </b>
             {` ${Timestamp.fromDate(new Date(subscription.created.seconds*1000 )).toDate().toDateString()}`}
          </p>
          <p className="lead"> <b>Current Period Started: </b>
             {` ${Timestamp.fromDate(new Date(subscription.current_period_start.seconds*1000 )).toDate().toDateString()}`}
          </p>
          <p className="lead"> <b>Auto Renews On: </b> 
             {`${Timestamp.fromDate(new Date(subscription.current_period_end.seconds*1000 )).toDate().toDateString()}`}
          </p>
          {isPending ? <button className="btn btn-danger mb-5"><span><Spinner animation="border" size="sm" role='status' variant="light"/> Manage Subscription</span></button> : 
            <button className="btn btn-danger" onClick={manageSubscription}>Manage Subscription</button> }
          
         </div> : <Spinner animation="grow" />}
      </div>

      <ModalComponent showModal={showModal}
         handleClose={closeModal}
         title='Change personal information'>
           <Form>
             <Form.Group>
               <Form.Control className="mb-2" 
                 placeholder="Name"
                 value={name}
                 onChange={(e) => setName(e.target.value)} /> 
               {/* <Form.Control placeholder="Email" />  */}

               <Form.Label className="lead text-muted mt-3"> Select Image
               <Form.Control 
                 className='image-upload'
                 type='file'
                 onChange = { handleFileChange }>
               </Form.Control>
               </Form.Label>
               <div className='flex text-center'>
                 <p className='text-muted lead'>Image Preview</p>
                 {error && <p>{ error }</p>}
                 {url && 
                   <Image 
                     height='120px'
                     width='120px'
                     className='rounded-circle mb-3'
                     src = { url }
                     alt='Menu Item'>
                   </Image>}
                 {file && 
                   <ProgressBar 
                     striped 
                     now={progress} 
                     label={`${progress}%`}
                />}
             </div>
             </Form.Group>
             <Form.Group className="text-center">
                <Button onClick={handleSubmit} className="btn btn-danger mt-3">Submit</Button>
             </Form.Group>
           </Form>
         </ModalComponent>
     </main>
    </>
    )
}