import { db } from '../../../firebaseconfig/config'
import { collectionGroup, query, where, getDocs, orderBy } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import BasicPlanFeatures from "./BasicPlanFeatures"
import StandardPlanFeatures from "./StandardPlanFeatures"
import PremiumPlanFeatures from "./PremiumPlanFeatures"


export default function PricingCard({ product }) {
     const navigate = useNavigate()
     const [ productPrices, setProductPrices ] = useState([])
     
     useEffect(() => {
          const loadPrices = async () => { 
            const prices = query(collectionGroup(db, 'prices'), orderBy("unit_amount"), where('product', '==', product.id))
            const querySnapshot = await getDocs(prices)
            let pricesArray = []
            querySnapshot.forEach((doc) => {
              pricesArray.push(product.name, doc.data())
            })
            setProductPrices(pricesArray)
          }
          loadPrices()
     },[product])

    return (
      <div class="card mb-4 shadow-lg border-0">
        <div class="card-header mb-3 shadow bg-danger text-white">
          <h4 class="my-0 display-6">{ product.name }</h4>
        </div>
        <div class="card-body">
            {productPrices && productPrices.map(price => {
                let amount = parseFloat(price.unit_amount)/100
                return ( !amount ? "" : <>
                  <h3 class=" display-6 ">
                      { amount } usd<small class="text-muted">{` / ${price.interval}`}</small>
                  </h3> </>)})}

                  {(() => {
                    switch (product.name) {
                      case 'Basic Plan':
                          return <BasicPlanFeatures />
                      case 'Standard Plan':
                          return <StandardPlanFeatures />
                      case 'Premium Plan':
                          return <PremiumPlanFeatures />
                      default:
                          return
                    }
                  })()}
    
                <button name={product.name}
                        key={product.id}
                        onClick={(() => {navigate(`/signup/${product.id}`)})}
                      class="btn btn-lg btn-block btn-danger">
                      Select Plan
                </button> 
        </div>
      </div>
    )
}