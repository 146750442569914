export default function Footer(){
    return(
        <>
          <footer className="footer lead mt-3 py-3">
              <div className="container-fluid ">
                  <div className="row h-100">
                      <div className="col">
                       <p className="text-muted">Ordena Now, LLC 2021</p>
                      </div>
                  </div>
              </div>
          </footer>
        </>
    )
}