import { Fade } from 'react-awesome-reveal'

export default function Features(){

  const fadeDelay = 0
    return(
      <div id='features' className="features text-center pb-5">
        <div className="container pt-5 pb-5">
          <h1 className='display-3'>Features</h1>
          <p className='display-6'>Our goal is to help you increase your sales. 
             Ordena Now digital menus allow you to beatifully showcase 
             your products and dynamically change prices and promotions. 
          </p>
        </div>
       <div className='container'>
         <div className='row pb-5'>
           <Fade delay={fadeDelay} className='text-center col-md-6 col-md-push-6'>
             <div className='px-5 py-1'>
             <p></p>
             <h3 className='display-6 pb-2'>DYNAMIC MENUS</h3>
             <p className='lead'>Transform your menus into beautiful dynamic web pages accessible via QR code.
                 Add, edit or remove menu items from anywhere (works with PC and ) no app required.
             </p>
             </div>
           </Fade>
           <Fade delay={fadeDelay} className='text-center col-md-6'>
           <img className='main-img shadow-lg' 
            src={require('../../../Assets/food-photo.jpg')}
            alt='' />
           </Fade>
         </div>

        <div className='row d-flex'>
          <Fade delay={fadeDelay}  className='text-center col-md-6 order-2 order-md-1'>
            <img className='main-img shadow-lg' 
             src={require('../../../Assets/table-with-menus.jpg')}
             alt='' />
          </Fade> 
          <Fade delay={fadeDelay} className='text-center col-md-6 order-1 order-md-2'>
            <div className='px-5 py-5'>
            <p></p>  
            <h3 className='display-6 pb-2'>CREATE MULTIPLE MENUS</h3>
            <p className='lead'>Have different menus during the day? Ordena now allows you to create different QR codes and manage
                your menus individually. 
            </p>
            </div>
          </Fade>
        </div>
    

        <div className='row d-flex'>
          <Fade delay={fadeDelay} className='text-center col-md-6'>
            <div className='px-5 py-5'>
            <p></p>
            <h3 className='display-6 pb-2'>Social Media Integration</h3>
            <p className='lead'>Drive customers to your social media pages. Links to all your social media platforms can be access
                from you digital menus.
            </p>
            </div>
          </Fade>
          <Fade delay={fadeDelay} className='text-center col-md-6'>
          <img className='main-img shadow-lg' 
           src={require('../../../Assets/social-media.jpg')}
           alt='' />
          </Fade>
        </div>

        <div className='row d-flex'>
          <Fade delay={fadeDelay} className='text-center col-md-6 order-2 order-md-1'>
            <img className='main-img shadow-lg' 
             src={require('../../../Assets/display.jpg')}
             alt='' />
          </Fade> 
          <Fade delay={fadeDelay} className='text-center col-md-6 order-1 order-md-2'>
            <div className='py-5 px-5'>
            <p></p>
            <h3 className='display-6 pb-2'>DIGITAL SIGNAGE INTEGRATION</h3>
            <p className='lead'>Go beyond digital QR menus. Add a welcome digital sign, daily promotions or showcase your products in gorgeous 
                flat screens. All you product information such as price and photo will be automatically 
                updated accross devices. Take your business to the next level.
            </p>
            </div>
          </Fade>
        </div>

        <div className='row d-flex'>
          <Fade delay={fadeDelay} className='text-center col-md-6'>
            <div className='py-5 px-5'>
            <h3 className='display-6 pb-2'>Showcase your products with Beautiful photos</h3>
            <p className='lead'>Ordena Now allows you to showcase your product with photos and video (coming soon). 
            </p>
            </div>
          </Fade>
          <Fade delay={fadeDelay} className='text-center col-md-6'>
          <img className='main-img shadow-lg' 
           src={require('../../../Assets/food-photo.jpg')}
           alt='' />
          </Fade>
        </div>

        <div className='row d-flex'>
          <Fade delay={fadeDelay} className='text-center col-md-6 order-2 order-md-1'>
            <img className='main-img shadow-lg' 
             src={require('../../../Assets/bilingual.png')}
             alt='' />
          </Fade> 
          <Fade delay={fadeDelay} className='text-center col-md-6 order-1 order-md-2'>
            <div className='px-5 py-5'>
            <h3 className='display-6'>MULTIPLE LANGUAGES</h3>
            <p className='lead'>Create multilingual menus. Make your international customers feel welcomed allowing them
                to change the menu to their native language.  
            </p>
            </div>
          </Fade>
        </div>
        </div>
      </div>
    )
}