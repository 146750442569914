import { Carousel } from "react-bootstrap";
import '../../CustomerMenuPage/customer-menu-style.css'

// eslint-disable-next-line import/no-anonymous-default-export
export default function Offers( { offers }){
    return(
        <Carousel className="h-100">

       {offers.map(offer => {
         return <Carousel.Item interval={5000}>
         <img
           className="carousel-img"
           src={offer.promotionImage}
           alt="Second slide"
         />
         <Carousel.Caption>
           <h1 className="display-6 font-weight-bold">{offer.promotionTitle}</h1>
           <p className="lead font-weight-bold">{offer.promotionDescription}</p>
           {/* <p className="lead">{`Offer Expires: ${offer.promotionExpires}`}</p> */}
         </Carousel.Caption>
       </Carousel.Item>
       })}

        </Carousel>
    )
}