import { useState } from "react"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useFirestore } from "../../hooks/useFirestore"
import { Button, Form } from "react-bootstrap"
import '../Styling/AddItemForm.css'

export default function LocationForm({ handleClose }) {
    const { user } = useAuthContext()
    const [locationName, setLocationName] = useState('')
    const [street, setStreet] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [phone, setPhone] = useState('')

    const locationRef = `users/${user.uid}/locations/`
    const { addDocument } = useFirestore(locationRef)

    const handleSubmit = (e) => {
        e.preventDefault()
        const location = {
            locationName: locationName,
            uid: user.uid,
            street: street,
            city: city,
            state: state,
            phone: phone,
        }
        addDocument(location)
        handleClose(false)
    }
    return(
        <Form onSubmit={handleSubmit}>
            <Form.Group className="form-group">
                <Form.Label>
                    Location Name
                </Form.Label>
                <Form.Control
                   value={locationName}
                   onChange={(e) => {setLocationName(e.target.value)}}
                   placeholder="El patron #2">
                </Form.Control>
            </Form.Group>
            <Form.Group className="form-group">
                <Form.Label>
                    Street
                </Form.Label>
                <Form.Control
                   value={street}
                   onChange={(e) => {setStreet(e.target.value)}}
                   placeholder="234 N Clark St">
                </Form.Control>
            </Form.Group>
            <Form.Group className="form-group">
                <Form.Label>
                    City
                </Form.Label>
                <Form.Control
                   value={city}
                   onChange={(e) => {setCity(e.target.value)}}
                   placeholder="Hammond">
                </Form.Control>
            </Form.Group>
            <Form.Group className="form-group">
                <Form.Label>
                    State
                </Form.Label>
                <Form.Control
                   value={state}
                   onChange={(e) => {setState(e.target.value)}}
                   placeholder="IN">
                </Form.Control>
            </Form.Group>
            <Form.Group className="form-group">
                <Form.Label>
                    Phone
                </Form.Label>
                <Form.Control
                   type="tel"
                   pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
                   value={phone}
                   onChange={(e) => {setPhone(e.target.value)}}
                   placeholder="773-567-8907">
                </Form.Control>
            </Form.Group>
            <hr></hr>
            <Form.Group className="form-group buttons fluid">
                <Button className="btn btn-secondary mr-3"
                        onClick={handleClose}>
                    Cancel
                </Button>
                <Button className="btn btn-danger"
                        type="submit">
                    Save Location
                </Button>
            </Form.Group>
        </Form>
    )
}