import { Fade } from 'react-awesome-reveal'
import '../../Styling/home.css'

export default function HomeMain(){
    return(      
    <Fade cascade delay={100}>
      <section className='container mb-5 text-center' >
        <div className='row mt-5 mb-5'>
        <div className='h-100 col-xs-6 col-md-6 col-lg-6 mt-5'>
          <h1 className='display-3'>Create Beautiful Digital Menus For Your Business!</h1>
          <div >
          <p className='display-7'>(Easily accessible via QR code)</p>
          <a className='btn btn-outline-danger' href='#pricing'>
              Sign Up
          </a>
          <p className='mt-3'  data-mdb-toggle="animation"
             data-mdb-animation-start="onClick"
             data-mdb-animation-on-scroll="repeat"
             data-mdb-animation="slide-in-left">
               No App or Website required!
          </p>
          </div>
        </div>
        <div className='col-xs-6 col-md-6 col-lg-6 mb-3 mt-5'>
          <iframe className='video-frame shadow' 
            src="https://player.vimeo.com/video/705477783?h=aa0be95b6e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
            frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture" 
            allowfullscreen 
            title="App Explainer">
          </iframe>
        </div>
        </div>
      </section>
  </Fade>)
}