import { useState } from "react";
import { Button, Form, ProgressBar, Image } from "react-bootstrap";
import { useFirestore } from "../../hooks/useFirestore";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useStorage } from "../../hooks/useStorage";
import { arrayUnion } from "firebase/firestore";
import '../Styling/AddItemForm.css'

export default function CategoryForm({ closeCategoryModal, id, category }){

    const [categoryName, setCategoryName] = useState('')
    const { user } = useAuthContext()
    const menuRef = `users/${user.uid}/locations/${id}/Menus/`
    const { addDocument } = useFirestore(menuRef)
    // Image upload const
    const [file, setFile] = useState(null)
    const [error, setError] = useState(null)
    const [folder, setFolder] = useState('')
    const types = ['image/png', 'image/jpeg', 'image/jpg']
    const { progress, url } = useStorage(file, folder, user.uid)

    const handleSubmit = (e) => {
      e.preventDefault()
      const doc = {
        Categories: arrayUnion (
         { categoryName: categoryName })}
         console.log("triggered category")
        
       addDocument(doc, category)
       closeCategoryModal(false)
    }

    const handleFileChange = (e) => {
        e.preventDefault()
        let selectedFile = e.target.files[0]
        if (selectedFile){
          if(types.includes(selectedFile.type)){
            setError(null)
            setFile(selectedFile)
            setFolder(menuRef)
          }else{
            setFile(null)
            setError('Please select an imagee file (png or jpg)')
          }
        }
    }
    return(
      <Form className='form container' onSubmit={handleSubmit}>
        <Form.Group className="form-group">
          <Form.Label>
              Category
          </Form.Label>
          <Form.Control 
            value={categoryName}
            placeholder="Ex. Breakfast, Drinks, Kids, etc"
            onChange={(e) => setCategoryName(e.target.value)}>
          </Form.Control>
        </Form.Group>
        <Form.Group className='form-group'>
          <Form.Label>
              Select Image
          <Form.Control className='image-upload'
                        type='file'
                        onChange={handleFileChange}>
          </Form.Control>
          </Form.Label>
          <div className='flex'>
          <p className='p-img-prev'>Image Preview</p>
              {error && <p>{ error }</p>}
              {url && <Image className='image-preview'
                             src={url}
                             alt='Menu Item'></Image>}
              {file && <ProgressBar striped now={progress} label={`${progress}%`}/>}
          </div>
        </Form.Group>  
        <hr></hr>
        <Form.Group className='form-group buttons fluid'>
          <Button 
              onClick={() => closeCategoryModal(false)}
              variant='secondary'>
              Cancel
          </Button>
      
          <Button 
              type='submit' 
              variant='danger'>
              Save
          </Button>
        </Form.Group>
      </Form>
    ) 
}