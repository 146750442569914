import '../Styling/card.css'
import { Trash2, Edit } from 'react-feather'
import { useFirestore } from '../../hooks/useFirestore'
import { useState } from 'react'
import ProductModal from '../Components/ProductModal'
import AddItemForm from './AddItemForm'


export default function Card({ menuCategories, product, menu, locationID}){

    const { name, description, price, calories, image, id, path } = product
    const { deleteDocument, addDocument } = useFirestore(path)
    const [ showProductModal, setShowProductModal ] = useState(false)

    const handleDelete = (e) => {
        e.preventDefault()
        let confirmDelete = window.confirm(`Are you sure you want to delete ${name}?`)
        if(confirmDelete){
        deleteDocument(id, path)
    }}
    const handleEdit = (e) => {
        e.preventDefault()
        let doc = {
            price: 300
        }
        addDocument(doc, id)
    }
     // closes modal 
  const handleClose = (e) => {
    e.preventDefault()
    setShowProductModal(false)
  }

    return(
      <div className='card item-card px-2 py-2 mb-3 shadow'>
        <div className='row'>
          <div className='col'>
            <img class="item-card-img" 
               src={ image }
               alt={ name }/>
          </div>
          <div className='col-sm-6'>
            <span><h5 class="item-card-title"> { name }  (${price})</h5></span>
           <span class="item-card-text"> { description }</span>
              {calories && <span class="item-card-text"> { calories } cal</span>}
          </div>
          <div className='col'>
            <Edit className='icons' onClick = { () => setShowProductModal(true) }/>
            <Trash2 className='icons' onClick = { handleDelete }/>
          </div>
        </div>
        <ProductModal showProductModal = { showProductModal } handleClose = {handleClose} >
          <AddItemForm 
            menuCategories = { menuCategories } 
            editProduct = { product } 
            handleClose = { handleClose }
            saveEdit = { handleEdit }
            id = { locationID }
            menu = { menu }>
          </AddItemForm>
        </ProductModal>
      </div>
    )
}
