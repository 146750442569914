import { Button, Form, Spinner } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import { useState } from "react";
import { useFirestore } from "../../../hooks/useFirestore";
export default function Contact(){

  const [ name, setName ] = useState('')
  const [ mail, setMail ] = useState('')
  const [ phone, setPhone ] = useState('')
  const [ message, setMessage ] = useState('')
  const [ isPending, setIsPending ] = useState(false)

  const emailCollection = 'salesProspects'
  const { addDocument } = useFirestore(emailCollection)

  function handleSubmit(e) {
    e.preventDefault()
    const email = {
      name: name,
      mail: mail,
      phone: phone,
      message: message,
      subject: "Potential Ordena Now Customer",
      sender: "sales@ordenanow.com"
    }
    addDocument(email)
    setIsPending(true)
    setTimeout(() => {
      handleReset()
      alert("Thank you we'll contact you soon!")
    }, 3000)
  }

  function handleReset() {
      setMail('')
      setName('')
      setMessage('')
      setPhone('')
      setIsPending(false)
  }
  
  return(
    <div id="contact" className="container-fluid form-section pt-4 pb-4 ">
       <div className="container w-75">
        <h1 className="text-center text-muted display-4 muted mb-3">Contact Us</h1>
        <p className="text-center display-6 text-muted mb-5">
            We're here to help, if you have questions regarding any of our services 
            do not hesitate to contact us. 
        </p>
       </div>
    <Fade>
       <Form onSubmit = { handleSubmit } 
          className="container bg-white contact-form text-muted pt-4 pb-4 pl-4 pr-4 rounded-3 shadow-lg">
          <Form.Group className="mb-3">
              <Form.Label className="lead">Name</Form.Label>
              <Form.Control type = "text" 
                placeholder = "Enter your name."
                value = { name }
                onChange = { (e) => setName(e.target.value) }/>
          </Form.Group>
          <Form.Group className="mb-3">
              <Form.Label className="lead">Email</Form.Label>
              <Form.Control type =  "email" 
                 placeholder = "Enter your email."
                 value = { mail }
                 onChange = { (e) => setMail(e.target.value) }/>
              <Form.Text>We'll never share your email with anyone else.</Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
              <Form.Label className="lead">Phone</Form.Label>
              <Form.Control type = "phone" 
                 placeholder = "Enter your phone number (optional)"
                 value = { phone }
                 onChange = { (e) => setPhone(e.target.value)}/>
              <Form.Text>We'll never share your phone number with anyone else.</Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
              <Form.Label className="lead">How can we help!</Form.Label>
              <Form.Control
                as={'textarea'} 
                placeholder="Please tell us how we can help."
                value = { message }
                onChange = { (e) => setMessage(e.target.value) }/>
          </Form.Group>
          <Form.Group className="text-center">
              <Button className="btn btn-danger"
                 type="submit">
                  {isPending ? <span><Spinner animation='border' size="sm"/> Sending . . .</span>: 'Submit' }
              </Button>
          </Form.Group>
       </Form>
     </Fade>    
    </div>
  )
}