import { Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../Styling/Header.css'

export default function NavBar(props){
 return( 
      <Navbar collapseOnSelect expand='md' sticky='top' variant='dark' className='nav-container align-center shadow-lg'>
      <Navbar.Brand href="/">
          <img
            alt=""
            src={require('../../Assets/logo.png')}
            width="40"
            height="40"
            className="d-inline-block p-0 mr-1 align-center"
          /> ORDENA NOW
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
          <Nav className='nav'>
            <Nav.Link  eventKey='1' href="/">Home</Nav.Link>
            <Nav.Link  eventKey='2' href="/#features">Features</Nav.Link>
            <Nav.Link  eventKey='3' href="/#about">About</Nav.Link>
            <Nav.Link  eventKey='4' href="/#pricing">Pricing</Nav.Link>
            <Nav.Link  eventKey='5' href="/#contact">Contact</Nav.Link>
            <Nav.Link className='btn btn-custom' as={Link} eventKey='4' to="/signin">Log In</Nav.Link>
            {/* <Nav.Link className='btn btn-custom' as={Link} eventKey='5' to="/signup">Sign Up</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
  
 )
}