import { auth } from '../firebaseconfig/config'
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

export const useResetPassword = () => {


  const navigate = useNavigate()
  const resetPassword = (email) => {
      
    sendPasswordResetEmail(auth, email)
    .then(() => {
    // Password reset email sent!
      alert('Reset link sent to your email address!')
      navigate('/')
    // ..
    })
    .catch((error) => {
      alert('Check your email and try again!')
    // ..
  });
}
return { resetPassword }
}