import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollection } from "../../hooks/useCollection";
import { useState } from "react";
import { Button } from "react-bootstrap";
import SideBarTest from "../Components/SideBarTest";
import ProductModal from "../Components/ProductModal";
import AddPromotionForm from "../Components/AddPromotionForm";
import PromotionCard from "../Components/PromotionCard";


export default function Promotions(){
    const { user, authRole } = useAuthContext()
    const [ showProductModal, setShowProductModal ] = useState(false)
    const handleClose = () => setShowProductModal(false)
    const promotionsCollectionRef = `users/${user.uid}/locations/`
    const { documents: locations } = useCollection(promotionsCollectionRef)
    const [ locationID, setLocationID ] = useState('null')
    const { documents: promotions }  = useCollection(`${promotionsCollectionRef}${locationID}/promotions`) 

    return (
    <>
      <SideBarTest user = { user }/>
      {console.log(authRole)}
      <main class="location col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 class="display-6 text-muted">Promotions</h1>
          <div class="btn-toolbar mb-2 mb-md-0">
            <div class="btn-group me-4">
              {locationID === 'null' ? "" : 
              <Button 
                onClick={() => setShowProductModal(true)}
                className="btn btn-md btn-danger">
                  + Promotion
              </Button>}
            </div>
              <select onChange={(e) => setLocationID(e.target.value)}> 
                <option value='null'>Select Location</option>
                 {locations && locations.map((location) => {
                 return <option 
                    key={location.id}
                    value={location.id}>
                     {location.locationName}
                  </option>
                })}
              </select>
              <ProductModal 
                  handleClose = { handleClose }
                  showProductModal = { showProductModal }>
                   <AddPromotionForm 
                     handleClose={ handleClose } 
                     promotionsCollectionRef = {`${promotionsCollectionRef}${locationID}/promotions`}/>
               </ProductModal>
            </div>
        </div>
        <div className="text-center mt-5">
          {locationID === 'null' ? 
            <div>
              <h1 className="display-3 text-muted">
                Please select a location
              </h1>
              <p className="lead">If you have not created a location, 
               create one here to start managing your promotions. 
              </p>
              <a className="btn btn-danger" href="/location">+ Location</a>
            </div> : ""}
        </div>
        {console.log(promotions)}
        {promotions && promotions.map(promotion => { 
          return <PromotionCard 
            locationID = {locationID} 
            promotion={ promotion }/>
          })}
      </main>  
    </>
    )
}