import { Modal } from 'react-bootstrap'


export default function CategoryModal({ children, handleClose, showCategoryModal }){
  return(
      <Modal className='modal' show={showCategoryModal} centered>
        <Modal.Header closeButton onClick={handleClose}>
        </Modal.Header>
          <Modal.Body>
           { children }
          </Modal.Body>
      </Modal>
  )
}