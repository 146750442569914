import { useEffect, useState } from 'react'
import { Form, Button, Image, ProgressBar} from 'react-bootstrap'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFirestore } from '../../hooks/useFirestore'
import { useStorage } from '../../hooks/useStorage'

import '../Styling/AddItemForm.css'

export default function AddItemForm({ closeProductModal, id, menu, menuCategories, editProduct }){
    
    // Menu object properties
    const [name, setName] = useState('')
    const [category, setCategory] = useState(id)
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [calories, setCalories] = useState('')
    const [path, setPath] = useState('')
    const [favorite, setFavorite] = useState(false)
    const [editProductId, setEditProductId] = useState('')

    // Image upload const
    const [file, setFile] = useState(null)
    const [error, setError] = useState(null)
    const [folder, setFolder] = useState('')
    const types = ['image/png', 'image/jpeg', 'image/jpg']
    const { user } = useAuthContext()

    const collectionString = `users/${user.uid}/locations/${id}/Menus/${menu}/${category}`
    const { addDocument, updateDocument } = useFirestore(collectionString)
    const { progress, url } = useStorage(file, folder, user.uid)

    const handleSubmit = (e) => {
      e.preventDefault()
      const doc = {
        uid: user.uid,
        name: name,
        category: category,
        description: description,
        price: price,
        image: url,
        path: collectionString,
        calories: calories,
        favorite: favorite,
      }
      
      if(!editProduct){
         addDocument(doc)
      } else {
         console.log(editProduct)
         updateDocument(doc, editProductId)
      }
      closeProductModal(false)
    }
    useEffect(() => {
      if(editProduct){
        setName(editProduct.name)
        setCategory(editProduct.category)
        setDescription(editProduct.description)
        setPrice(editProduct.price)
        setCalories(editProduct.calories)
        setEditProductId(editProduct.id)
        setPath(editProduct.path)
      }
    }, [editProduct])

    const handleFileChange = (e) => {
        e.preventDefault()
        let selectedFile = e.target.files[0]
        if (selectedFile){
            if(types.includes(selectedFile.type)){
                setError(null)
                setFile(selectedFile)
                setFolder('/MenuItems/')
            }else{
                setFile(null)
                setError('Please select an image file (png or jpg)')
            }
        }
    }

    return(
      <Form className='form container' onSubmit={handleSubmit}>
        <Form.Label>
            Product Category
        </Form.Label>

        <Form.Select 
          className='form-group' 
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
          aria-label="Default select example">
          <option> </option>
          { menuCategories.Categories && menuCategories.Categories.map(category => { 
            return <option 
              value= {category.categoryName}
              key={category.id}>
              { category.categoryName } </option>})}   
        </Form.Select>

        <Form.Group className='form-group'>
          <Form.Label>
            Product
          </Form.Label>
          <Form.Control 
            value={name}
            placeholder='Product Name ex. Chilaquiles'
            onChange={(e) => setName(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group className='form-group'>
          <Form.Label>
            Description
          </Form.Label>
          <Form.Control
            value={description} 
            placeholder='Rojos con crema, cebolla, cilantro y frijoles.'
            onChange={(e) => setDescription(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group className='form-group'>
          <Form.Label>
          Calories
          </Form.Label>
          <Form.Control
            value={calories} 
            placeholder='Opcional ex 345'
            onChange={(e) => setCalories(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group className='form-group'>
          <Form.Label>
          Price
          </Form.Label>
          <Form.Control
            value={price} 
            placeholder='11'
            onChange={(e) => setPrice(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group className='form-group'>
          <Form.Label> Select Image
          <Form.Control 
            className='image-upload'
            type='file'
            onChange = { handleFileChange }>
          </Form.Control>
          </Form.Label>
            <div className='flex'>
            <p className='p-img-prev'>Image Preview</p>
              {error && <p>{ error }</p>}
              {url && 
                <Image 
                  className='image-preview'
                  src = { url }
                  alt='Menu Item'>
                </Image>}
              {file && 
                <ProgressBar 
                  striped 
                  now={progress} 
                  label={`${progress}%`}
                />}
             </div>
        </Form.Group>

        <hr></hr>
        
        <Form.Group className='form-group buttons fluid'>
          <Button 
              onClick={() => closeProductModal(false)}
              variant='secondary'>
              Cancel
          </Button>
        
          <Button 
              type='submit' 
              variant='danger'
              disabled={ progress < 100 ? true : false}
              onClick = {handleSubmit}>
              Save
          </Button>
        </Form.Group>
     </Form>
    )
}