import { useState } from "react"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useCollection } from "../../hooks/useCollection"
import { useFirestore } from '../../hooks/useFirestore'
import { useParams } from "react-router-dom"
import { FormSelect } from "react-bootstrap"


export default function NewUser() {

    const { user } = useAuthContext()
    const params = useParams()

    const priceCollectionRef = `products/${params.productid}/prices`
    const userCollectionRef = `users/${user.uid}/checkout_sessions`
    const userRef = `users`
    
    const [ displayName, setDisplayName ] = useState('')
    const [ selectPrice, setSelectPrice ] = useState('')
    const [ phone, setPhone ] = useState ('')

    const { documents: prices } = useCollection(priceCollectionRef)
    const { addDocument } = useFirestore(userCollectionRef)
    const { updateDocument } = useFirestore(userRef)
    const { documents: checkoutSessions } = useCollection(userCollectionRef)


    const handleSubmit = (e) => {

      e.preventDefault()
      const newUser = {
        displayName: displayName,
        phone: phone,
        photoURL: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png',
      }

      updateDocument(newUser, user.uid)

      const checkoutDoc = {
        price: selectPrice,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      }
     
     addDocument(checkoutDoc)

      checkoutSessions.forEach(doc => {
        const { error, url } = doc
        if(error){
          alert(error.message)
        }
        if(url){
          window.location.assign(url)
        }
      })
    }

    return(
      <div className="container w-75">
        <h1 className="text-center text-muted mb-5 mt-5">Create Account</h1>
        <form className="container"
          onSubmit={handleSubmit}>
          <div className="form-floating">
            <input  
              required 
              onChange={(e) => setDisplayName(e.target.value)} 
              value={displayName}
              type="text" 
              class="form-control mb-2" 
              id="floatingInput" 
              placeholder="Jonas Jones"/>
            <label 
              HTMLfor="floatingInput">Full Name
            </label>
          </div>
          <div className="form-floating">
            <input  
              required 
              onChange={(e) => setPhone(e.target.value)} 
              value={phone}
              type="phone" 
              class="form-control mb-2" 
              id="floatingInput" 
              placeholder="(xxx) xxx-xxxx "/>
            <label 
              HTMLfor="floatingInput">Phone
            </label>
          </div>
          <div className="form-floating">
            <FormSelect className='mb-2 w-100'  
              value={selectPrice} 
              onChange={(e) => {setSelectPrice(e.target.value)}}>
              <option className="my-auto">Select plan</option>
              {prices && prices.map((price) => { 
                return <option className="my-auto"  
                    key={price.id}
                    value={price.id}
                    >
                    {price.unit_amount/100} usd / {price.interval}
                  </option>
                })}
            </FormSelect>
          </div>
          <div className="text-center">
            <button 
              className="w-50 mt-4 btn btn-lg btn-danger" 
              type="submit">Sign Up
            </button>
          </div>
        </form>
        </div>
    )
}